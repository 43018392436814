if (typeof window !== 'undefined') {
var langType = window.location.pathname.split('/');
langType = langType[1];
var prefix;
var link_vehicles = "/luxury-cars-sarajevo";
var link_locations = "/where-to-rent-luxury-cars-sarajevo";
var link_about = "/about-vip-cars-sarajevo";
var link_privacy = "/privacy-policy-of-vip-cars-sarajevo";
var link_reserve = "/reserve-your-luxury-vip-car";
if(langType == "en")
{
prefix = "/en";
}
else
{
prefix = "";
link_vehicles = "/luksuzna-vozila";
link_locations = "/gdje-iznajmiti-luksuzno-vozilo";
link_about = "/o-vip-cars-sarajevo";
link_privacy = "/politika-privatnosti-vip-cars-sarajevo";
link_reserve = "/rezervisite-vase-vip-cars-vozilo";
}
}
export {prefix, link_vehicles, link_locations, link_about, link_privacy, link_reserve};