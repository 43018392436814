import React from 'react'
import Swiper from 'react-id-swiper';
import slide1 from "../../images/gallery/volvo/slide1.jpg"
import slide2 from "../../images/gallery/volvo/slide2.jpg"
import slide3 from "../../images/gallery/volvo/slide3.jpg"
import slide4 from "../../images/gallery/volvo/slide4.jpg"
import slide5 from "../../images/gallery/volvo/slide5.jpg"
import slide6 from "../../images/gallery/volvo/slide6.jpg"
import slide7 from "../../images/gallery/volvo/slide7.jpg"
import slide8 from "../../images/gallery/volvo/slide8.jpg"
import slide9 from "../../images/gallery/volvo/slide9.jpg"
import slide10 from "../../images/gallery/volvo/slide10.jpg"
import slide11 from "../../images/gallery/volvo/slide11.jpg"
import slide12 from "../../images/gallery/volvo/slide12.jpg"

class PartnersSlider extends React.Component {
    render() {
        const params = {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
              },
              loop: true,  
          }
   
      return(
                    <Swiper {...params}>
                        <div className="slide"><img src={slide1} alt="Volvo XC90 - Vip Cars Sarajevo/Rent a Car" /></div>
                        <div className="slide"><img src={slide2} alt="Volvo XC90 - Vip Cars Sarajevo/Rent a Car" /></div>
                        <div className="slide"><img src={slide3} alt="Volvo XC90 - Vip Cars Sarajevo/Rent a Car" /></div>
                        <div className="slide"><img src={slide4} alt="Volvo XC90 - Vip Cars Sarajevo/Rent a Car" /></div>
                        <div className="slide"><img src={slide5} alt="Volvo XC90 - Vip Cars Sarajevo/Rent a Car" /></div>
                        <div className="slide"><img src={slide6} alt="Volvo XC90 - Vip Cars Sarajevo/Rent a Car" /></div>
                        <div className="slide"><img src={slide7} alt="Volvo XC90 - Vip Cars Sarajevo/Rent a Car" /></div>
                        <div className="slide"><img src={slide8} alt="Volvo XC90 - Vip Cars Sarajevo/Rent a Car" /></div>
                        <div className="slide"><img src={slide9} alt="Volvo XC90 - Vip Cars Sarajevo/Rent a Car" /></div>
                        <div className="slide"><img src={slide10} alt="Volvo XC90 - Vip Cars Sarajevo/Rent a Car" /></div>
                        <div className="slide"><img src={slide11} alt="Volvo XC90 - Vip Cars Sarajevo/Rent a Car" /></div>
                        <div className="slide"><img src={slide12} alt="Volvo XC90 - Vip Cars Sarajevo/Rent a Car" /></div>
                    </Swiper>         
      )
    }
  }
   
  export default PartnersSlider
